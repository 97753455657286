<template>
  <div
    class="bookDetail"
    v-wechat-title="
      ($route.meta.title = bookdetail.name
        ? bookdetail.name + '-詳情-嗨文小說'
        : '嗨文小說')
    "
  >
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <a href="javaScript:" class="fr more" @click="seeMore">
          <img src="~img/share@2x.png" alt="" />
        </a>
      </div>
      <div :class="{ morebox: true, moreShow: moreShow }">
        <div class="moreCon">
          <div class="topbox clearfix allN">
            <a href="javaScript:" class="fl back" @click="seeBack">
              <img src="~img/back@2x.png" alt="" />
            </a>
            <p class="fl">{{ bookdetail.name }}</p>
            <a href="javaScript:" class="fr more" @click="cancle">
              <img src="~img/cancle.png" alt="" />
            </a>
          </div>
          <ul class="clearfix">
            <li class="fl">
              <router-link to="/">
                <img src="~img/home/home.png" alt="" />
                <p>首頁</p>
              </router-link>
            </li>
            <li class="fl">
              <router-link to="/collect">
                <img src="~img/home/bookshelf.png" alt="" />
                <p>書架</p>
              </router-link>
            </li>
            <li class="fl">
              <router-link to="/center">
                <img src="~img/home/user.png" alt="" />
                <p>賬戶</p>
              </router-link>
            </li>
          </ul>

          <router-link to="/recentlyRead" class="recentRead"
            >最近閱讀</router-link
          >
        </div>
      </div>
    </div>
    <div class="bookWrap">
      <div class="bookInfo clearfix">
        <img
          class="cover fl"
          v-if="bookdetail.iconUrl"
          :src="bookdetail.iconUrl"
          alt=""
        />
        <img class="cover fl" v-else src="~img/default.png" alt="" />
        <div class="infoBox fl">
          <div class="bookname clearfix">
            <span class="fl">{{ bookdetail.name }}</span>
            <span class="fl isfinish">{{
              bookdetail.finished ? "已完結" : "連載中"
            }}</span>
          </div>
          <a href="#" class="author">{{ authorDetail.name }}</a>
          <div class="infoSmall">
            4.6萬點擊 l {{ (bookdetail.words / 10000).toFixed(1) }}萬字 l 類型/{{
              bookdetail.sort
            }}
          </div>
          <a href="#">最後更新時間：{{ bookdetail.lastChapterTime }}</a>
        </div>
      </div>
      <div class="instroduce">
        {{ bookdetail.introduce }}
      </div>
      <div class="optionBox">
        <a
          href="javaScript:"
          @click="gochapter(firstchapter.id, firstchapter.free)"
          v-if="hisChapters == null"
          class="readbtn"
          >免費試讀</a
        >
        <a
          href="javaScript:"
          @click="gochapter(hisChapters.id, hisChapters.free)"
          v-if="hisChapters !== null"
          class="readbtn"
        >
          繼續試讀
        </a>
        <a
          href="javaScript:"
          class="addbtn"
          v-if="!bookisShelf"
          @click="useraddshelf"
        >
          加入書架
        </a>
        <a href="javaScript:" class="addbtn added" v-if="bookisShelf">
          已收藏
        </a>
        <a v-if="phoneType == 'android'" href="https://play.google.com/store/apps/details?id=com.haiwen.reader" class="autobtn">下載APP</a>
        <a v-if="phoneType == 'IOS'" href="https://apps.apple.com/tw/app/%E5%97%A8%E6%96%87%E5%B0%8F%E8%AA%AA/id1485535288" class="autobtn">下載APP</a>
      </div>
    </div>
    <!-- 目錄 -->
    <div class="bookMenu">
      <h2>目錄<span>連續更新11天</span></h2>
      <a
        href="javaScript:"
        @click="
          gochapter(bookdetail.lastChapter.id, bookdetail.lastChapter.free)
        "
        class="newChapter clearfix"
      >
        <span class="tag">最新</span>
        <span class="lastname ellipsis" v-if="bookdetail.lastChapter">{{
          bookdetail.lastChapter.name
        }}</span>
        <span class="time" v-if="bookdetail.lastChapter">{{
          bookdetail.lastChapter.publishTime
        }}</span>
      </a>
      <ul>
        <li v-for="book in bookdetail.chapterMenu" :key="book.id">
          <a href="javaScript:" @click="gochapter(book.id, book.free)">
            {{ book.name }}
          </a>
        </li>
        <div class="moreChapter clearfix">
          <span class="fl" @click="gocatalog(bookdetail.id)">更多章節</span>
          <img class="fl" src="~img/home/Path7@2x.png" alt="" />
        </div>
      </ul>
    </div>
    <div class="line"></div>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="recommend.content"
      :isInDEtail="true"
      :title="recommend.name"
      :shelfid="recommend.id"
    ></eightShelf>
  </div>
</template>

<script>
import { BookDetail } from "apiurl/book";
import { readstatus, addshelf, buyAuto } from "apiurl/user";
import { getToken } from "utils/storage";
export default {
  data() {
    return {
      bookId: "",
      bookdetail: {},
      authorDetail: {},
      booklist: [],
      firstchapter: {},
      isAuto: false,
      bookisShelf: false,
      hisChapters: null,
      recommend: {},
      moreShow: false,
      phoneType: 'IOS',
    };
  },
  created() {
    this.$nextTick(() => {
      this.bookId = this.$route.params.id;
      this.BookDetailInfo(this.$route.params.id);
      var ua = window.navigator.userAgent.toLowerCase()
      var ios = /iphone|ipod|ipad/.test(ua);
      if (ios) {
        this.phoneType = 'IOS'
      } else {
        this.phoneType = 'android'
      }
      if (getToken()) {
        this.getReStatus();
      }
    });
  },
  watch: {
    $route(to) {
      this.bookId = to.params.id;
      return this.BookDetailInfo(this.bookId);
    }
  },
  methods: {
    seeBack() {
      //   this.$router.go(-1);
      this.$router.push("/");
    },
    // 獲取書籍詳情信息
    BookDetailInfo(id) {
      BookDetail({ bookId: id }).then(res => {
        if (res.success) {
          this.bookdetail = res.data.book;
          this.bookdetail.introduce =
            this.bookdetail.introduce.substring(0, 150) + "...";
          this.authorDetail = res.data.book.author;
          this.booklist = res.data.book.chapterMenu;
          this.firstchapter = this.booklist[0];
          this.recommend = res.data.recommend
        }
      });
    },
    // 獲取讀者閱讀狀態
    async getReStatus() {
      let res = await readstatus({ bookId: this.bookId });
      if (res.success) {
        this.hisChapters = res.data.hisChapter;
        this.bookisShelf = res.data.isShelf;
        this.isAuto = res.data.isAuto;
      }
    },
    async useraddshelf() {
      let res = await addshelf({ bookId: this.bookId });
      if (res.success) {
        this.bookisShelf = true;
        this.Toast({
          message: "已加入",
          duration: 2000,
          position: "top"
        });
      }
    },
    gocatalog(id) {
      this.$router.push(`/catalog/${id}`);
    },
    gochapter(id, free) {
      this.$router.push(`/book/${this.bookId}/${id}`);
      localStorage.setItem("bookfree", free);
    },
    seeMore() {
      this.moreShow = true;
    },
    cancle() {
      this.moreShow = false;
    },
    changeAuto() {
      this.isAuto = !this.isAuto;
      if (this.isAuto) {
        buyAuto({ bookId: this.bookdetail.id, open: true }).then(res => {
          this.isAutoBuy = true;
          if (res.success) {
            this.Toast({
              message: "自動訂閱已開啓",
              duration: 2000,
              position: "top"
            });
          }
        });
      } else {
        buyAuto({ bookId: this.bookdetail.id, open: false }).then(res => {
          if (res.success) {
            this.Toast({
              message: "自動訂閱已關閉",
              duration: 2000,
              position: "top"
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.topbox
    height 100px
    line-height 100px
    padding 0 27px
    position relative
    background rgba(255, 255, 255, 1)
    box-shadow 0 0 0 0
    .more
        width 36px
        height 36px
        img
            width 100%
    .back
        width 50px
        height 37px
        line-height 100px
        margin-top 13.5px
        img
            width 18px
            height 37px
    p
        position absolute
        left 0px
        top 0px
        bottom 0px
        right 0px
        margin auto
        text-align center
        font-size 34px
        color #333333
        width 70%
.bookWrap
    margin 0px 16px 18px 24px
    padding-bottom 49px
    border-bottom 1px solid #eee
    .bookInfo
        .cover
            width 195px
            height 271px
            border-radius 8px
            border 1px solid rgba(213, 213, 213, 1)
            box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.2)
            margin-right 33px
        .infoBox
            padding-bottom 10px
            margin-top 10px
            width 465px
            overflow hidden
            .bookname
                font-size 34px
                font-weight 400
                color rgba(51, 51, 51, 1)
                margin-bottom 20px
                span
                    &:first-child
                        max-width 68%
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                .isfinish
                    padding 4px 10px
                    display inline-block
                    border-radius 19px
                    border 2px solid #d4dbe8
                    color #999999
                    font-size 18px
                    margin-top 3px
                    margin-left 17px
            .author
                color #2289F1
                margin-bottom 21px
                display block
            .infoSmall
                font-size 24px
                font-weight 400
                color rgba(85, 85, 85, 1)
                line-height 33px
                margin-bottom 22px
    .instroduce
        margin 30px 0 20px
        font-size 24px
        font-weight 400
        color rgba(85, 85, 85, 1)
        line-height 49px
        letter-spacing 1px
        text-indent 30px
    .optionBox
        a
            display inline-block
            width 212px
            height 64px
            text-align center
            line-height 64px
            border-radius 8px
            margin-right 30px
            color #fff
            &:nth-child(3n)
                margin-right 0
            &.readbtn
                background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
            &.autobtn
                background rgba(244, 248, 252, 1)
                border 2px solid rgba(238, 238, 238, 1)
                color #333333
            &.addbtn
                background rgba(244, 248, 252, 1)
                border 2px solid rgba(238, 238, 238, 1)
                color #333333
            &.added
                color #999
.bookMenu
    padding 0 24px
    h2
        font-size 34px
        color #333
        font-weight 400
        span
            display inline-block
            color #999999
            font-size 22px
            margin-left 13px
    .newChapter
        margin 29px 13px 27px 0
        display flex
        -webkit-display flex
        .tag
            background rgba(242, 164, 35, 1)
            padding 2px 6px 1px
            border-radius 3px
            font-size 20px
            text-align center
            color rgba(255, 255, 255, 1)
            margin-right 13px
        .time
            color rgba(242, 164, 35, 1)
        .lastname
            flex 1
            -webkit-flex 1
    ul
        position relative
        margin-bottom 40px
        li
            margin-bottom 25px
            a
                color #555555
                font-size 24px
        .moreChapter
            padding 7px 9px
            display inline-block
            font-size 24px
            border-radius 10px
            border 2px solid rgba(153, 153, 153, 1)
            position absolute
            bottom 0px
            right 0px
            img
                width 13px
                margin-left 7px
                margin-top 3px
.morebox
    position fixed
    width 100vw
    height 100vh
    z-index 10
    top 0
    background:rgba(0,0,0,0.49);
    transition all 0.17s ease
    opacity 0
    display none
    &.moreShow
        display block
        opacity 1
    .moreCon
         padding 0 0 67px 0
         background #fff
    .topbox
        box-shadow 0 0 0 0
    .more
        img
            width 30px
            height 31px
            margin-top 35px
    ul
        margin 69px 0 50px 120px
        li
            margin-right 142px
            &:nth-child(3n)
                margin-right 0
            img
                width 56px
                height 56px
            p
                text-align center
                color #323232
                font-size 28px
                margin-top 6px
    .recentRead
        width 294px
        height 68px
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
        border-radius 34px
        display block
        line-height 68px
        color #fff
        text-align center
        font-size 28px
        margin 0 auto
</style>
